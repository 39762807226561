import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/ui';
import { transformPage } from '../../utils/transforms';

export default createGatsbyPageComponent('Page', { debug: false, transform: transformPage });

export const query = graphql`
  query PageQuery($id: ID!) {
    platform {
      page(id: $id) {
        id slug path name title pretitle subtitle
        content { text }
        titleRich { id text }
        subtitleRich { id text }
        openGraph { 
          title description { plain }
          image { url url2x thumbnails { large { url url2x } } }
        }
        parent {
          id name path
        }
        typeLabel
        type { name slug }
        types { name slug }
        intro { text }
        outro { text }
        orderNumber
        files { id path name file { extension url } }
        family {
          id name slug path subtitle
        }
        moderators {
          __typename id name slug position path
          photo { id url url2x }
          organisation { id name slug }
        }
        authors {
          __typename id name slug position path
          photo { id url url2x }
          organisation { id name slug }
        }
        people {
          id name slug path __typename _schema { label pluralLabel }
          organisation { id name }
          position
          photo { id url url2x }
        }
        layout {
          id name slug component 
        }
        videos {
          id name url
          thumbnail { id url url2x }
        }
        trend {
          id name slug path outlineNumber color { value }
          sentimentAnalyses {
            id name slug
            images {
              id slug
              image { url url2x }
            }
            type { id name slug label color { id name value } }
            observations { 
              id name slug 
              description { text }
              topics {
                name slug path outlineNumber color { value }
              }
              subTopics {
                name slug path outlineNumber color { value }
              }
            }
          }
        }
        trends {
          name slug path outlineNumber __typename color { value }
        }
        connectedTrends {
          id name slug path outlineNumber __typename color { value }
        }
        connectedTopics {
          id name slug path outlineNumber __typename color { value }
        }
        connectedSubTopics {
          id name slug path outlineNumber __typename color { value }
        }
        connectedPages {
          id name typeLabel slug path __typename type { name slug }
          trend { id name slug path outlineNumber color { value } }
          
        }
        embeds {
          citations {
            slug url name authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
          }
          trends {
            id name slug path outlineNumber __typename _schema { label pluralLabel } color { slug value }
            openGraph {
              title description { plain }
              image { url url2x }
            }
          }
          topics { 
            id path outlineNumber slug name color { id value } __typename _schema { label pluralLabel } 
            openGraph {
              title description { plain }
              image { url url2x }
            }
          }
          subTopics { 
            id path outlineNumber slug name color { id value } __typename _schema { label pluralLabel }
            openGraph {
              title description { plain }
              image { url url2x }
            }
          }
          events {
            id slug path 
            name
            startDate
            endDate
            description { text }
            color { id value darkValue }
            images { image { id url url2x width height } }
            links { id url name label isDownload page { path } }
            sessions { 
              id name __typename 
              startDate endDate
              icsPath(base: "/downloads")
              description { text }
              room { id name shortName }
              color { id veryLightValue lightValue value darkValue }
              presenters { 
                id name 
                bio { html }
                photo { url url2x }
                openGraph { title description { plain } image { url url2x thumbnails { faceMedium { url url2x } } } }
              }
              group { id name shortName }
            }
          }
          pages {
            id name slug path __typename _schema { label pluralLabel }
            title
            openGraph {
              title description { plain(truncate: {words: 40}) }
              image { url url2x }
            }
          }
          people {
            id name slug path
            organisation { id name }
            position
            photo { id url url2x }
          }
          imageAssets {
            id
            slug
            image {
              id url url2x width height
            }
          }
        }
        bannerLinks {
          id name slug url __typename
          type { id name }
          typeLabel
          banner {
            id name slug
            image { id url url2x width height thumbnails { halfBanner { url url2x } mainBanner { url url2x } } }
          }
          page { 
            id name slug path __typename 
            type { id name }
            title
            subtitle
            openGraph { id image { id url url2x } }
          }
          bannerTitle
          bannerSubtitle
          openGraph { id image { id url thumbnails { card { url url2x } } } }
        }
        cardLinks {
          id name path slug url __typename
          type { id name }
          typeLabel
          page { 
            id name slug path __typename 
            typeLabel
            type { id name }
            title
            subtitle
            openGraph { id image { id url url2x } }
          }
          event { 
            id name slug path __typename 
            typeLabel
            title
            subtitle
            openGraph { id image { id url url2x } }
          }
          openGraph { id image { id url url2x thumbnails { card { url url2x } } } }
        }
        children {
          __typename id name path slug title subtitle pretitle 
          typeLabel
          openGraph { 
            image { url url2x thumbnails { card { url url2x } } }
          }
          people {
            id name slug path
            organisation { id name }
            position
            photo { id url url2x }
          }
          orderNumber
        }
        feedEntries {
          id name slug url published websiteName summary { id text }
          __typename _schema { label pluralLabel }
          openGraph { image { url url2x } }
        }
        banner {
          id name slug title description { text } alternateText
          image { 
            id url url2x width height thumbnails { halfBanner { url url2x } } 
          }
        }
      }
    }
  }
`;